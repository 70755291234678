var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.handleadd
    }
  }, [_vm._v("新增")]), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.addcolumns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.addcolumns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "imgurls" ? _c("span", [record.imgurl ? _c("img", {
            staticClass: "product_img",
            attrs: {
              src: _vm.splitImg(record)
            },
            on: {
              click: function click($event) {
                _vm.preview(_vm.splitImg(record));
              }
            }
          }) : _c("span", [_vm._v(" --- ")])]) : key == "managementcategory" ? _c("span", [_c("span", [_vm._v(_vm._s(record.parentcategoryname + "-" + record.categoryname))])]) : key == "operation" ? _c("span", [_c("a", {
            on: {
              click: function click($event) {
                return _vm.deletegoods(record);
              }
            }
          }, [_vm._v("删除")])]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };